.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.typing-indicator {
  background-color: #E6E7ED; /* Replaced $ti-color-bg with the actual color */
  will-change: transform;
  width: auto;
  border-radius: 33px; /* 50px * 2/3 */
  padding: 13.33px; /* 20px * 2/3 */
  position: relative;
  animation: 2s bulge infinite ease-out;
  display: inline-block;
}

.typing-indicator::before,
.typing-indicator::after {
  content: '';
  position: absolute;
  bottom: -1.33px; /* -2px * 2/3 */
  left: -1.33px; /* -2px * 2/3 */
  height: 13.33px; /* 20px * 2/3 */
  width: 13.33px; /* 20px * 2/3 */
  border-radius: 50%;
  background-color: #E6E7ED; /* Replaced $ti-color-bg with the actual color */
}

.typing-indicator::after {
  height: 6.67px; /* 10px * 2/3 */
  width: 6.67px; /* 10px * 2/3 */
  left: -6.67px; /* -10px * 2/3 */
  bottom: -6.67px; /* -10px * 2/3 */
}

.typing-indicator span {
  height: 10px; /* 15px * 2/3 */
  width: 10px; /* 15px * 2/3 */
  float: left;
  margin: 0 0.67px; /* 1px * 2/3 */
  background-color: #9E9EA1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}

.typing-indicator span:nth-of-type(1) {
  animation: 1s blink infinite 0s;
}

.typing-indicator span:nth-of-type(2) {
  animation: 1s blink infinite 0.3333s;
}

.typing-indicator span:nth-of-type(3) {
  animation: 1s blink infinite 0.6666s;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}